import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "@firebase/firestore";
import { BannerFormData } from "../../constants/features/banner/banner";

// Firebase Firestore Data Converter
export const bannerConverter: FirestoreDataConverter<BannerFormData> = {
  toFirestore(data: BannerFormData) {
    return {
      isDisplay: data.isDisplay,
      imageSrc: data.imageSrc,
      linkUrl: data.linkUrl,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): BannerFormData {
    const data = snapshot.data(options);
    return {
      isDisplay: data.isDisplay,
      imageSrc: data.imageSrc,
      linkUrl: data.linkUrl,
    };
  },
};
