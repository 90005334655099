import styles from "../assets/styles/banner.module.scss";

type BannerProps = {
  src: string;
  href: string;
  alt?: string;
};

export const Banner = ({ src, href, alt = "banner" }: BannerProps) => {
  return (
    <div className={styles.banner}>
      <a href={href} target="_blank" rel="noreferrer">
        <img src={src} alt={alt} />
      </a>
    </div>
  );
};
