import styles from "../../../../assets/styles/interview.module.scss";
import { useFetchActorList } from "../../../../hooks/admin/useFetchActorList";

export const Footer = ({
  title,
  actors,
}: {
  title: string | undefined;
  actors: string[] | undefined;
}) => {
  const { data } = useFetchActorList();
  const filteredActors = data?.filter((actor) => actors?.includes(actor.id));

  const shareText = `${title}- PAZLE Creator’s interview&via=Pazle_info&hashtags=PAZLE,声優,ショートストーリー,朗読,${filteredActors?.map(
    (actor) => actor.name.replaceAll(" ", ""), // 姓名の間にスペースがある際に削除する
  )}`;

  return (
    <footer className={styles.footer}>
      <div className={styles.footerInner}>
        <h3 className={styles.subTitle}>その他の新作作品も順次公開予定！</h3>
        <ul className={styles.btns}>
          <li>
            <p>＼友達にも共有しよう／</p>
            <a
              href={`http://twitter.com/share?url=pazle.click/interview/fuyuzora-melt&text=${shareText}`}
              target="_brank"
              className={`${styles.btn} ${styles.btnSmall} ${styles.btnTertiary} ${styles.btnTw}`}
            >
              SHARE
            </a>
          </li>
        </ul>
        <div className={styles.snsLinks} id="sns">
          <a
            href="https://twitter.com/Pazle_info"
            target="_brank"
            className={`${styles.btnTw}`}
          >
            フォローする
          </a>
          <a
            href="https://lin.ee/r4SVWtA"
            target="_brank"
            className={styles.btnLine}
          >
            <img
              src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
              alt="友だち追加"
            />
          </a>
        </div>
        <div className={styles.inner}>
          <div className={styles.backToTopWrap}>
            <a className={styles.backToTop} href="#main">
              <img
                src={
                  require("../../../../assets/images/top_arrow_top.svg").default
                }
                alt=""
              />
            </a>
          </div>
          <ul className={styles.linkList}>
            <li>
              <a href="https://vectordesign.jp/" target="_brank">
                運営会社について
              </a>
            </li>
            <li>
              <a href="/terms" target="_brank">
                利用規約
              </a>
            </li>
            <li>
              <a href="/privacy" target="_brank">
                プライバシーポリシー
              </a>
            </li>
            <li>
              <a href="/tokutei" target="_brank">
                特定商取引法に基づく表記
              </a>
            </li>
            <li>
              <a href="/requirements" target="_brank">
                動作環境について
              </a>
            </li>
          </ul>
          <div className={styles.copylight}>
            <small>©︎2023</small>
            <img
              src={
                require("../../../../assets/images/top_logo_vector.svg").default
              }
              className={styles.vector}
              alt=""
            />
            <span>/</span>
            <img
              src={require("../../../../assets/images/logo_white.svg").default}
              className={styles.pazle}
              alt=""
            />
          </div>
        </div>
      </div>
    </footer>
  );
};
