import { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { bannerConverter } from "./bannerConverter";

type BannerData = {
  imageSrc: string;
  linkUrl: string;
  isDisplay: boolean;
};

export const useFetchBanner = () => {
  const [bannerData, setBannerData] = useState<BannerData | undefined>(
    undefined,
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchBanner = async () => {
      const bannerDoc = doc(db, "banner", "banner").withConverter(
        bannerConverter,
      );
      const docSnap = await getDoc(bannerDoc);

      if (docSnap.exists()) {
        setBannerData(docSnap.data() as BannerData);
      } else {
        console.log("No such document!");
      }
      setIsLoading(false);
    };

    fetchBanner();
  }, []);

  return {
    bannerData,
    isLoading,
  };
};
