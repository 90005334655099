import { Dispatch, SetStateAction, useState } from "react";
import styles from "../assets/styles/paymentMethodModal.module.scss";
import stylesIcon from "../assets/styles/icon.module.scss";
import {
  HttpsCallable,
  httpsCallable,
  HttpsCallableResult,
} from "firebase/functions";
import { analytics, db, functions } from "../firebase";
import { UserProf } from "../utils/types";
import { useAuthContext } from "./AuthContext";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import { logEvent } from "firebase/analytics";

export const PaymentMethodModal = ({
  contentId,
  title,
  thumbnailUrl,
  chapter,
  setShownModal,
  closeButton = true,
}: {
  contentId: string;
  title: string;
  thumbnailUrl: string;
  chapter: { chapter: number; price: number }[];
  setShownModal: Dispatch<SetStateAction<string | null>>;
  closeButton?: boolean;
}) => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<"stripe" | null>(null);

  const purchaseAtStripe = async () => {
    if (!user) return;

    logEvent(analytics, "select_payment_methods", {
      payment_methods: "stripe",
    });

    (async () => {
      setIsLoading("stripe");
      const uid = user.uid;
      const uri = new URL(window.location.href);
      const uriOrigin = uri.origin;

      const userProf = await getUser(uid).catch(() => {
        alert("エラーが発生しました。\nしばらくしてから再度お試しください。");
        return null;
      });
      let stripeId = userProf?.stripeId;

      if (!stripeId) {
        stripeId = await createCustomer(user!.email!)
          .then((customer) => customer.data as string)
          .catch(() => {
            alert(
              "エラーが発生しました。\nしばらくしてから再度お試しください。",
            );
            setIsLoading(null);
            return undefined;
          });

        if (stripeId) {
          const isSucceeded = await setUserStripeId(uid, stripeId)
            .then(() => true)
            .catch(() => {
              alert(
                "エラーが発生しました。\nしばらくしてから再度お試しください。",
              );
              setIsLoading(null);
              return false;
            });

          if (!isSucceeded) {
            setIsLoading(null);
            return;
          }
        } else {
          setIsLoading(null);
          return;
        }
      }
      const sessionId = await createCheckoutSession(
        contentId,
        thumbnailUrl,
        title,
        chapter,
        stripeId,
        uriOrigin,
      )
        .then((result) => result.data.id as string)
        .catch(() => {
          alert("エラーが発生しました。\nしばらくしてから再度お試しください。");
          return null;
        });
      if (!sessionId) {
        setIsLoading(null);
        return;
      }

      createStripeObj()
        .then((stripe) => {
          if (stripe) stripe.redirectToCheckout({ sessionId: sessionId });
        })
        .catch(() => {
          alert("エラーが発生しました。\nしばらくしてから再度お試しください。");
        })
        .finally(() => setIsLoading(null));
    })();
  };

  const createCustomer = async (
    email: string,
  ): Promise<HttpsCallableResult<unknown>> => {
    const createCustomer = httpsCallable(functions, "createCustomer");
    return createCustomer({
      email: email,
    });
  };

  const createCheckoutSession = async (
    contentId: string,
    thumbnailUrl: string,
    title: string,
    chapter: { chapter: number; price: number }[],
    stripeId: string,
    uriOrigin: string,
  ) => {
    const createCheckoutSession = httpsCallable(
      functions,
      "createCheckoutSession",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) as HttpsCallable<unknown, any>;
    return createCheckoutSession({
      name: title,
      chapter: chapter,
      image: thumbnailUrl,
      customerId: stripeId,
      successUrl:
        chapter.length === 1
          ? `${uriOrigin}/my-contents?id=${contentId}&chapter=${chapter[0].chapter}&session_id={CHECKOUT_SESSION_ID}`
          : `${uriOrigin}/my-contents?id=${contentId}&chapter=all&session_id={CHECKOUT_SESSION_ID}`,
      cancelUrl: `${uriOrigin}/contents?id=${contentId}`,
    });
  };

  const createStripeObj = async (): Promise<Stripe | null> => {
    const STRIPE_SECRET_KEY = process.env.REACT_APP_STRIPE_SECRET_KEY;
    if (!STRIPE_SECRET_KEY) return null;
    const stripePromise = loadStripe(STRIPE_SECRET_KEY);
    const stripe = await stripePromise;
    return stripe;
  };

  const setUserStripeId = (uid: string, stripeId: string) => {
    return setDoc(
      doc(db, "users", uid),
      {
        stripeId: stripeId,
      },
      { merge: true },
    );
  };

  const getUser = async (uid: string): Promise<UserProf | null> => {
    const ref = doc(db, "users", uid);
    const snap = await getDoc(ref);
    if (snap.exists()) {
      return {
        id: snap.id,
        stripeId: snap.data().stripeId,
      };
    } else {
      return null;
    }
  };

  return (
    <div className={styles.paymentModal}>
      <div className={styles.inner}>
        <h2>以下の方法で支払う</h2>
        <p>※外部のサービスに遷移します</p>
        <div className={styles.btn_close_wrap}>
          <button
            type="button"
            className={styles.btn_close}
            onClick={() => {
              setShownModal(null);
              if (!closeButton) navigate(-1);
            }}
          >
            <i className={stylesIcon.icon_close}></i>
          </button>
        </div>
        <div className={styles.buttons}>
          <div className={`${styles.button} ${styles.stripe}`}>
            <button
              type="button"
              onClick={purchaseAtStripe}
              disabled={isLoading === "stripe" ? true : false}
            >
              {isLoading === "stripe" ? (
                "処理中..."
              ) : (
                <>
                  <img
                    src={require("../assets/images/payment_visa.png")}
                    alt=""
                  />
                  <img
                    src={require("../assets/images/payment_master.png")}
                    alt=""
                  />
                  <img
                    src={require("../assets/images/payment_jcb.png")}
                    alt=""
                  />
                  <img
                    src={require("../assets/images/payment_ae.png")}
                    alt=""
                  />
                  <img
                    src={require("../assets/images/payment_diners.png")}
                    alt=""
                  />
                </>
              )}
            </button>
            <p>クレジットカード</p>
          </div>
        </div>
      </div>
      <div
        className={styles.overlay}
        onClick={() => {
          setShownModal(null);
          if (!closeButton) navigate(-1);
        }}
      ></div>
    </div>
  );
};
