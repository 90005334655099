import React from "react";
import { getAuth } from "firebase/auth";
import {
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  query,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import { createRef, MutableRefObject, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import HeadBlock from "../../components/HeadBlock";
import Header from "../../components/Header";
import Nav from "../../components/Nav";
import { displayContentCategory } from "../../enums/contentCategory";
import { analytics, db, functions } from "../../firebase";
import { Actor, Chapter, Content, UserProf } from "../../utils/types";
import styles from "../../assets/styles/myContents.module.scss";
import stylesLoading from "../../assets/styles/loading.module.scss";
import { useAuthContext } from "../../components/AuthContext";
import { Button } from "../../components/Button";
import { httpsCallable } from "firebase/functions";
import { useSwipeable } from "react-swipeable";
import { ContentsView } from "../../components/ContentsView";
import { SigninModal } from "../../components/SigninModal";
import { logEvent } from "firebase/analytics";
import { MyContentCard } from "./MyContentCard";
// import ReactFreezeframe from "./ReactFreezeframe";

function MyContentsList() {
  const navigate = useNavigate();
  const [contents, setContents] = useState<Content[]>([]);
  const [purchasedContents, setPurchasedContents] = useState<Content[]>([]);
  const auth = getAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [isLoadingVideo, setIsLoadingVideo] = useState<boolean>(false);
  const { user } = useAuthContext();
  const [searchParams] = useSearchParams();
  const [showContentsModal, setShowContentsModal] = useState<string | null>(
    null,
  );
  const containerRef = createRef() as MutableRefObject<HTMLDivElement>;
  const [shownModalSignin, setShownModalSignin] = useState<string | null>(null);
  const [isLoadingPurchase, setIsLoadingPurchase] = useState<boolean>(false);
  const [isShownModalCompleted, setIsShownModalCompleted] =
    useState<boolean>(false);
  const [finishedFetch, setFinishedFetch] = useState<boolean>(false);

  // const refSnowmanS = createRef<HTMLDivElement>();
  // const refSnowmanL = createRef<HTMLDivElement>();
  // const freezeS = createRef<ReactFreezeframe>();
  // const freezeL = createRef<ReactFreezeframe>();

  useEffect(() => {
    if (searchParams.get("isPlaying"))
      navigate("/my-contents", { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchParams.get("session_id") || searchParams.get("transactionId"))
      return;

    if (!searchParams.get("isPlaying")) {
      setShowContentsModal(null);
    } else {
      if (!searchParams.get("id")) return;
      const currentContent = purchasedContents.filter(
        (c) => c.id === searchParams.get("id"),
      )[0];
      const chapter = Number(searchParams.get("chapter"));
      if (!currentContent) return;

      if (
        !currentContent.chapters.filter((ch) => ch.index === chapter)[0]
          .videoUrl
      ) {
        setIsLoadingVideo(true);
        getVideoUrl(currentContent, chapter).then((url) => {
          currentContent.chapters.filter(
            (ch) => ch.index === chapter,
          )[0].videoUrl = url;
          setShowContentsModal(currentContent.id);
          setIsLoadingVideo(false);
          setShowContentsModal(currentContent.id);
        });
      } else {
        setIsLoadingVideo(false);
        setShowContentsModal(currentContent.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (
      !searchParams.get("session_id") ||
      !searchParams.get("chapter") ||
      !searchParams.get("id") ||
      !finishedFetch ||
      !user
    )
      return;
    (async () => {
      const isPurchased = await isPurchasedContent(
        user?.uid,
        searchParams.get("id") as string,
        searchParams.get("chapter") as string,
      ).catch(() => {
        alert("エラーが発生しました。\nしばらくしてから再度お試しください。");
        return;
      });

      if (!isPurchased) {
        setIsLoadingPurchase(true);
        const stripeSessionId = searchParams.get("session_id");

        if (!stripeSessionId) {
          return;
        }

        purchasedContent(
          stripeSessionId,
          searchParams.get("id") as string,
          searchParams.get("chapter") as string,
        )
          .then(() => {
            setIsShownModalCompleted(true);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).twq("event", "tw-ofkye-ofypq", {});
            logEvent(analytics, "completed_purchase", {
              timing: localStorage.getItem("purchase_button_click_location"),
              unpurchased: true,
            });
          })
          .catch(() => {
            refund(stripeSessionId);
          })
          .finally(() => {
            fetchPurchasedContents(user?.uid)
              .then((pc) => {
                fetchContents(pc)
                  .then((contents) => {
                    setContents(contents);

                    setPurchasedContents(
                      contents.map((c) => {
                        console.log(c.id);
                        const chapters = c.chapters.filter((ch) => {
                          const purchasedChapters = pc.filter(
                            (pc) => pc.id === c.id,
                          )[0].chapters;
                          return purchasedChapters.includes(ch.index);
                        });
                        return {
                          id: c.id,
                          title: c.title,
                          category: c.category,
                          actors: c.actors.map((a: Actor) => {
                            return {
                              name: a.name,
                              role: a.role,
                              image: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/contents%2Factors%2F${a.image}.png?alt=media`,
                            } as Actor;
                          }),
                          chapters: chapters,
                          bgColor: c.bgColor,
                        } as Content;
                      }),
                    );
                  })
                  .catch(() => {
                    alert(
                      "エラーが発生しました。\nしばらくしてから再度お試しください。",
                    );
                  })
                  .finally(() => {
                    setLoading(false);
                    setIsLoadingPurchase(false);
                    navigate(
                      `?id=${searchParams.get("id")}&chapter=${searchParams.get(
                        "chapter",
                      )}`,
                    );
                  });
              })
              .catch(() => {
                alert(
                  "エラーが発生しました。\nしばらくしてから再度お試しください。",
                );
              });
          });
      } else {
        setLoading(false);
        if (searchParams.get("chapter") !== "all")
          navigate(
            `?id=${searchParams.get("id")}&chapter=${searchParams.get(
              "chapter",
            )}`,
          );
        else navigate(`?id=${searchParams.get("id")}&chapter=1`);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishedFetch]);

  const isPurchasedContent = async (
    uid: string,
    contentId: string,
    chapter: string,
  ): Promise<boolean> => {
    if (chapter !== "all") {
      const docSnap = await getDoc(
        doc(db, "users", uid, "contents", contentId, "chapters", chapter),
      );
      return docSnap.exists();
    } else {
      const docSnap = await getDocs(
        query(
          collection(db, "users", uid, "contents", contentId, "chapters"),
          where(documentId(), "in", ["2", "3"]),
        ),
      );
      return docSnap.docs.length > 0;
    }
  };

  const purchasedContent = async (
    sessionId: string,
    contentId: string,
    chapter: string,
  ): Promise<Content | undefined> => {
    const userProf = await getUser(user!.uid);
    const stripeId = userProf?.stripeId;
    const getCheckoutSession = httpsCallable(functions, "getCheckoutSession");
    const checkoutSession = await getCheckoutSession({
      sessionId,
    }).then((result) => result.data);

    const { status, customerId, isRefunded } = checkoutSession as {
      status: string;
      customerId: string;
      email: string;
      title: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      isRefunded: any;
    };
    if (
      status !== "complete" ||
      stripeId !== customerId ||
      isRefunded ||
      !contentId
    ) {
      return undefined;
    }

    setUserContent(user!.uid, contentId, chapter, sessionId!);
  };

  const getUser = async (uid: string): Promise<UserProf | null> => {
    const ref = doc(db, "users", uid);
    const snap = await getDoc(ref);
    if (snap.exists()) {
      return {
        id: snap.id,
        stripeId: snap.data().stripeId,
      };
    } else {
      return null;
    }
  };

  const setUserContent = async (
    uid: string,
    contentId: string,
    chapter: string,
    sessionId: string,
  ) => {
    const docSnap = await getDoc(doc(db, "users", uid, "contents", contentId));
    if (!docSnap.exists()) {
      await setDoc(
        doc(db, "users", uid, "contents", contentId),
        {
          createdAt: Timestamp.now(),
        },
        { merge: true },
      );
    }

    if (chapter !== "all") {
      await setDoc(
        doc(db, "users", uid, "contents", contentId, "chapters", chapter),
        {
          type: "stripe",
          sessionId: sessionId,
          createdAt: Timestamp.now(),
        },
      );
    } else {
      await setDoc(
        doc(db, "users", uid, "contents", contentId, "chapters", "2"),
        {
          type: "stripe",
          sessionId: sessionId,
          createdAt: Timestamp.now(),
        },
      );
      await setDoc(
        doc(db, "users", uid, "contents", contentId, "chapters", "3"),
        {
          type: "stripe",
          sessionId: sessionId,
          createdAt: Timestamp.now(),
        },
      );
    }
  };

  const getVideoUrl = async (c: Content, chapter: number): Promise<string> => {
    if (c.chapters.filter((ch) => ch.index === chapter)[0].price === 0) {
      const getContentUrl = httpsCallable(functions, "getContentUrl");
      const url = getContentUrl({
        contentId: `${c.id}_${
          c.chapters.filter((ch) => ch.index === chapter)[0].index
        }`,
      }).then((result) => result.data as string);
      return url;
    } else {
      const getContentUrl = httpsCallable(functions, "getContentUrl");
      if (c.chapters.length > 1) {
        const url = getContentUrl({
          contentId: `${c.id}_${c?.chapters.filter(
            (ch) => ch.index === chapter,
          )[0].index}`,
        }).then((result) => result.data as string);
        return url;
      } else {
        const url = getContentUrl({ contentId: c.id }).then(
          (result) => result.data as string,
        );
        return url;
      }
    }
  };

  const handlers = useSwipeable({
    onSwiped: (event) => {
      if (event.dir === "Up" || event.dir === "Down") {
        navigate(`/my-contents?id=${searchParams.get("id")}`, {
          replace: true,
        });
      }
    },
    trackMouse: true,
  });

  const fetchPurchasedContents = async (
    uid: string,
  ): Promise<{ id: string; chapters: number[] }[]> => {
    const snapShot = await getDocs(collection(db, "users", uid, "contents"));
    return await Promise.all(
      snapShot.docs.map(async (content) => {
        const chapterSnapShot = await getDocs(
          collection(db, "users", uid, "contents", content.id, "chapters"),
        );
        const chapters = chapterSnapShot.docs.map((ch) => Number(ch.id));
        if (!chapters.includes(1)) chapters.unshift(1);
        return {
          id: content.id,
          chapters,
        };
      }),
    );
  };

  const fetchContents = async (
    purchasedContents: { id: string; chapters: number[] }[],
  ): Promise<Content[]> => {
    if (purchasedContents.length === 0) {
      return [];
    }

    const snapShot = await getDocs(
      query(
        collection(db, "contents"),
        where(
          documentId(),
          "in",
          purchasedContents.map((c) => c.id),
        ),
      ),
    );
    return await Promise.all(
      snapShot.docs.map(async (content) => {
        const snapShot = await getDocs(
          collection(db, "contents", content.id, "chapters"),
        );

        const chapters = snapShot.docs.map((ch) => {
          return {
            index: Number(ch.id),
            actors: ch.data().actors.map((a: Actor) => {
              return {
                name: a.name,
                role: a.role,
                image: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/contents%2Factors%2F${a.image}.png?alt=media`,
              } as Actor;
            }),
            price: ch.data().price,
            outline: ch.data().outline,
            sampleTotalTime: ch.data().sampleTotalTime,
            totalTime: ch.data().totalTime,
          } as Chapter;
        });

        return {
          id: content.id,
          title: content.data().title,
          category: displayContentCategory(content.data().category),
          actors: content.data().actors.map((a: Actor) => {
            return {
              name: a.name,
              role: a.role,
              image: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/contents%2Factors%2F${a.image}.png?alt=media`,
            } as Actor;
          }),
          highlight: content.data().highlight,
          chapters: chapters,
          thumbnailUrl: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/contents%2Fthumbnails%2F${content.id}.jpg?alt=media`,
          bgColor: content.data().bgColor,
        } as Content;
      }),
    );
  };

  useEffect(() => {
    const uid = auth.currentUser?.uid;
    if (uid) {
      fetchPurchasedContents(uid)
        .then((pc) => {
          fetchContents(pc)
            .then((contents) => {
              if (contents.length === 0) return;
              setContents(contents);
              setPurchasedContents(
                contents.map((c) => {
                  const chapters = c.chapters.filter((ch) => {
                    const purchasedChapters = pc.filter(
                      (pc) => pc.id === c.id,
                    )[0].chapters;
                    return purchasedChapters.includes(ch.index);
                  });
                  return {
                    id: c.id,
                    title: c.title,
                    category: c.category,
                    actors: c.actors.map((a: Actor) => {
                      return {
                        name: a.name,
                        role: a.role,
                        image: `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/contents%2Factors%2F${a.image}.png?alt=media`,
                      } as Actor;
                    }),
                    chapters: chapters,
                    bgColor: c.bgColor,
                  } as Content;
                }),
              );
            })
            .catch((e) => {
              alert(e);
            })
            .finally(() => {
              setLoading(false);
              setFinishedFetch(true);
            });
        })
        .catch(() => {
          alert("エラーが発生しました。\nしばらくしてから再度お試しください。");
        });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.currentUser?.uid]);

  const refund = (sessionId: string) => {
    const refund = httpsCallable(functions, "refund");
    refund({
      sessionId: sessionId,
    }).finally(() => {
      alert(
        "決済処理中にエラーが発生しため、返金いたしました。\nしばらくしてから再度お試しください。",
      );
      navigate("/my-contents");
    });
  };

  if (loading) {
    return (
      <>
        <HeadBlock title={"購入作品"} path="my-contents" />
        <Header />
        <div className={styles.MyContents}>
          <div className={stylesLoading.loading}>
            <p>Loading...</p>
          </div>
        </div>
        <Nav />
      </>
    );
  } else {
    return (
      <>
        <HeadBlock title={"購入作品"} path="my-contents" />
        <Header />
        <div className={styles.MyContents}>
          {!user ? (
            <div className={styles.non_login}>
              <img
                src={require("../../assets/images/non-login_mycontents.png")}
                alt=""
              />
              <h2>購入した作品が表示されます</h2>
              <button type="button" onClick={() => navigate("/signin")}>
                ログイン / 登録
              </button>
            </div>
          ) : purchasedContents.length > 0 ? (
            <div className={styles.inner}>
              <h1>購入作品</h1>
              <div style={{ position: "relative" }}>
                <ul className={styles.libraries}>
                  {purchasedContents.map((c) => (
                    <li key={c.id}>
                      {/* memo: 冬に使用 */}
                      {/* {i === 0 && <div onClick={() => {
                            if (refSnowmanS.current && refSnowmanL.current && freezeS.current && freezeL.current) {
                              refSnowmanS.current.style.pointerEvents = "none";
                              refSnowmanL.current.style.pointerEvents = "none";
                              refSnowmanS.current.style.zIndex = "2";
                              refSnowmanL.current.style.zIndex = "2";
                              freezeS.current.stop();
                              freezeL.current.stop();

                              const num = Math.floor(Math.random() * 10);

                              if (num === 0) {
                                refSnowmanL.current.classList.add(styles.snowman_tate);
                                refSnowmanS.current.classList.add(styles.snowman_tate);
                              } else {
                                refSnowmanL.current.classList.add(styles.snowman_yoko);
                                refSnowmanS.current.classList.add(styles.snowman_yoko);
                              }

                              const bottom2 = window.innerHeight - document.getElementsByClassName(styles.snowman_l)[0].getBoundingClientRect().top - document.getElementsByClassName(styles.snowman_l)[0].clientHeight;
                              const left2 = document.getElementsByClassName(styles.snowman_l)[0].getBoundingClientRect().left - document.getElementsByClassName(styles.snowman_l)[0].clientHeight;
                              refSnowmanL.current.style.position = "fixed";
                              refSnowmanL.current.style.top = "unset";
                              refSnowmanL.current.style.right = "unset";
                              refSnowmanL.current.style.bottom = `${bottom2 - 52}px`;
                              refSnowmanL.current.style.left = `${left2 + 4}px`;
                              const bottom = window.innerHeight - document.getElementsByClassName(styles.snowman_s)[0].getBoundingClientRect().top - document.getElementsByClassName(styles.snowman_s)[0].clientHeight;
                              const left = document.getElementsByClassName(styles.snowman_s)[0].getBoundingClientRect().left - document.getElementsByClassName(styles.snowman_s)[0].clientHeight;
                              refSnowmanS.current.style.position = "fixed";
                              refSnowmanS.current.style.top = "unset";
                              refSnowmanS.current.style.right = "unset";
                              refSnowmanS.current.style.bottom = `${bottom - 47}px`;
                              refSnowmanS.current.style.left = `${left - 12.5}px`;

                              setTimeout(() => {
                                if (!refSnowmanL.current) return;
                                refSnowmanL.current.style.bottom = "55px";
                                setTimeout(() => {
                                  if (!refSnowmanS.current) return;
                                  refSnowmanS.current.style.bottom = "55px";
                                }, 100);
                              }, 100);

                              if (num !== 0) {
                                setTimeout(() => {
                                  if (!freezeL.current) return;
                                  freezeL.current.start();
                                  setTimeout(() => {
                                    if (!freezeS.current) return;
                                    freezeS.current.start();
                                  }, 100);
                                }, 800);
                              }
                            }
                          }}>
                            <div ref={refSnowmanS} className={`${styles.snowman} ${styles.snowman_s}`}>
                              <ReactFreezeframe
                                ref={freezeS}
                                src={require('../../assets/images/snowman_s.gif')}
                                options={{
                                  trigger: false,
                                  overlay: false,
                                }} />
                            </div>
                            <div ref={refSnowmanL} className={`${styles.snowman} ${styles.snowman_l}`}>
                              <ReactFreezeframe
                                ref={freezeL}
                                src={require('../../assets/images/snowman_l.gif')}
                                options={{
                                  trigger: false,
                                  overlay: false,
                                }} />
                            </div>
                          </div>} */}
                      <MyContentCard content={c} />
                    </li>
                  ))}
                </ul>
              </div>
              <div className={styles.btn_wrap}>
                <Button variant="secondary" onClick={() => navigate("/")}>
                  <span>他の作品を探す</span>
                </Button>
              </div>
            </div>
          ) : (
            <div className={styles.empty}>
              <img
                src={require("../../assets/images/empty_mycontents.png")}
                alt=""
              />
              <h2>
                まだ視聴可能な作品が
                <br />
                ありません
              </h2>
              <p>
                「ホーム」から作品を視聴後、
                <br />
                ご購入お願いします。
              </p>
              <button type="button" onClick={() => navigate("/")}>
                作品を探す
              </button>
            </div>
          )}

          {isLoadingPurchase && (
            <div className={styles.loadingPurchase}>
              <div className={styles.inner}>
                <p>購入処理中...</p>
              </div>
            </div>
          )}
          {isShownModalCompleted && (
            <div className={styles.modal}>
              <div className={styles.modalInner}>
                <h2>購入が完了しました！</h2>
                <p>さっそく作品を視聴してみましょう！</p>
                <iframe
                  id="iframe"
                  src=""
                  title="app"
                  style={{ display: "none" }}
                />
                <div className={styles.btns}>
                  <Button onClick={() => setIsShownModalCompleted(false)}>
                    OK
                  </Button>
                </div>
              </div>
              <div
                className={styles.overlay}
                onClick={() => setIsShownModalCompleted(false)}
              ></div>
            </div>
          )}

          {isLoadingVideo && (
            <div className={styles.loading_video}>
              <p>読み込み中...</p>
            </div>
          )}
        </div>
        <Nav />

        {showContentsModal && (
          <div ref={containerRef}>
            <div {...handlers}>
              <ContentsView
                containerRef={containerRef}
                c={contents.filter((c) => c.id === showContentsModal)[0]}
                currentContentId={showContentsModal}
                purchasedContent={
                  purchasedContents
                    .filter((c) => c.id === showContentsModal)
                    .map((c) => {
                      return {
                        id: c.id,
                        chapters: c.chapters.map((ch) => ch.index),
                      };
                    })[0]
                }
                setShowContentsModal={setShowContentsModal}
              />
            </div>
          </div>
        )}

        {shownModalSignin && (
          <SigninModal
            setShownModalSignin={setShownModalSignin}
            shownModalSignin={shownModalSignin}
            chapter={[Number(searchParams.get("chapter"))]}
          />
        )}
      </>
    );
  }
}

export default MyContentsList;
